.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.checkboxDark {
  opacity: 0;
  position: absolute;
}

.labelDark {
  width: 40px;
  height: 15px;
  background-color: #111;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  position: relative;
  transform: scale(1.5);
}

.ball {
  width: 14px;
  height: 14px;
  background-color: white;
  position: absolute;
  top: 1px;
  left: 4px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

.checkboxDark:checked+.labelDark .ball {
  transform: translateX(16px);
}

.video_modal_container {
  width: 65vw !important;
  max-width: 65vw !important;
}

.video_modal_container_out {
  border: solid 2px var(--imp-text-color) !important;
  border-radius: 15px;
}